html {
  font-size: 5px;
}

html,
body,
#root {
  height: 100%;
  font-family: "Helvetica Neue", Helvetica, Arial, "Noto Sans", sans-serif;
}
